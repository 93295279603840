<template>
  <div class="set-point">
    <!-- 测点信息 -->
    <div class="div">
         <p class="emColor title">测点信息</p>
        <ul class="box center flexW msgList">
          <li class="box center" v-for="item in msgList.slice(0,5)" :key="item.id">
            <div class="box center labelTxt">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="item.icon"></use>
              </svg>
              <span>{{item.title}}</span>
            </div>
            <div class="contentTxt ellipsis">{{item.value}}</div>
          </li>
        </ul>
        <ul class="box center flexW msgList">
          <li class="box center" v-for="item in msgList.slice(5,10)" :key="item.id">
            <div class="box center labelTxt">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="item.icon"></use>
              </svg>
              <span>{{item.title}}</span>
            </div>
            <div class="contentTxt">{{item.value}}</div>
          </li>
        </ul>
        <ul class="box center flexW msgList">
          <li class="box center" v-for="item in msgList.slice(10,11)" :key="item.id">
            <div class="box center labelTxt">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="item.icon"></use>
              </svg>
              <span>{{item.title}}</span>
            </div>
            <div class="contentTxt">{{item.value}}</div>
          </li>
        </ul>

    </div>
  
    <!-- 数据预处理设置 -->
    <div class="multi-form">
  
      <!-- 数据二次处理 -->
        <!-- 基线校正 -->
      <p>数据二次处理</p>
      <section class="rehandle">
        <span class="title">基线校正</span>
        <el-form :model="formData4" :inline="true" :rules="rules4" ref="formData4">
          <el-form-item label="是否进行基线校正处理">
            <el-select v-model="formData4.status" placeholder="请选择" :disabled="disabled1">
              <el-option label="是" value="1"/>
              <el-option label="否" value="0"/>
            </el-select>
          </el-form-item>
          <el-form-item label="类别参数" v-if="formData4.status==1"  prop="">
              <el-select v-model="formData4.status1" placeholder="请选择">
                <el-option label="振荡加突变" value="1"/>
                <el-option label="低频扰动加非线性趋势" value="2" title="低频扰动加非线性趋势"/>
              </el-select>
          </el-form-item>
          <el-form-item label="时间间隔" v-if="formData4.status==1"  prop="interval">
            <el-input v-model="formData4.interval" placeholder="请输入" clearable/>
            <span class="unit">秒</span>
          </el-form-item>
          <el-form-item label="时段长度" v-if="formData4.status==1"  prop="num1">
            <el-input v-model="formData4.num1" placeholder="请输入" clearable/>
            <span class="unit">分钟</span>
          </el-form-item>
          <el-form-item label="规则系数" v-if="formData4.status==1 && formData4.status1==1"  prop="num2">
            <el-input v-model="formData4.num2" placeholder="请输入" clearable/>
          </el-form-item>
           <el-form-item label="截止频率" v-if="formData4.status==1 && formData4.status1==2"  prop="num3">
            <el-input v-model="formData4.num3" placeholder="请输入" clearable/>
            <span class="unit">Hz</span>
          </el-form-item>
        </el-form>
      </section>
        <!-- 动静分离 -->
      <section class="rehandle" v-if = "zhendong == true">
        <span class="title">动静分离</span>
        <el-form :model="formData5" :inline="true" :rules="rules5" ref="formData5">
          <el-form-item label="是否进行动静分离处理">
            <!-- :disabled="type==1?true:false" -->
            <el-select v-model="formData5.status" placeholder="请选择" :disabled="disabled2">
              <el-option label="是" value="1"/>
              <el-option label="否" value="0"/>
            </el-select>
          </el-form-item>
          <el-form-item label="时间间隔" v-if="formData5.status==1 && this.formData4.status == 1" prop="">
            <el-input v-model="formData4.interval" placeholder="请输入"  :disabled="true" clearable/>
            <span class="unit">秒</span>
          </el-form-item>
          <!-- formData4.status -->
          <el-form-item label="时间间隔" v-if="formData5.status==1 && this.formData4.status == 0" prop="">
            <el-input v-model="formData5.num3" placeholder="请输入"  clearable/>
            <span class="unit">秒</span>
          </el-form-item>
          <el-form-item label="时段长度" v-if="formData5.status==1" prop="num1">
            <el-input v-model="formData5.num1" placeholder="请输入" clearable/>
            <span class="unit">分钟</span>
          </el-form-item>
          <el-form-item label="截止频率" v-if="formData5.status==1" prop="num2">
            <el-input v-model="formData5.num2" placeholder="请输入" clearable/>
              <span class="unit">Hz</span>
          </el-form-item>
        </el-form>
      </section>
    </div>
  
    <div class="btns">
      <el-button v-if="state ==1" @click="saveData">保存</el-button>
      <el-button v-if="state ==2" class="hui">保存</el-button>
      <el-button  @click="back" >返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var text1=(rule, value,callback)=>{    //不能为0，小数保留1位，正数
        let reg = /^[+]?(([0-9]\d*))(\.\d{1})?$/;      
        let flag = reg.test(value);

        if(!flag){
            callback(new Error('输入正数且最多一位小数'))
        }
        else if(value == 0){
            callback(new Error('输入正数且最多一位小数'))
        }
        else {
              callback()
        }
    };
     var text3=(rule, value,callback)=>{    //不能为0，小数保留1位，正数
        let reg = /^[+]?(([0-9]\d*))(\.\d{1,3})?$/;      
        let flag = reg.test(value);

        if(!flag){
            callback(new Error('输入正数且最多三位小数'))
        }
        else if(value == 0){
            callback(new Error('输入正数且最多三位小数'))
        }
        else {
              callback()
        }
    };
    


    var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入'));
        } else
         if (value > 3600) {
              callback(new Error('不能大于3600秒')); //24小时
            } else {
              callback();
            }
    };
    var checkAge1 = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入'));
        } else
         if (value > 360) {
              callback(new Error('不能大于360分钟'));  //不限制 小时
            } else {
              callback();
            }
    };
    var checkedSerialNumber = (rule, value, callback) => {
      if (value) {
        if (!Number(value)) callback("请输入数字");
      } else {
        callback();
      }
    };
    var checkedSerialNumber1 = (rule, value, callback) => {
       let reg = /^[0-9]+([.]{1}[0-9]+){0,1}$/;
       let flag = reg.test(value);

        if(!flag){
            callback(new Error('请输入正数'))
        }
        else if (!value){
              callback(new Error('请输入！'))
        }     
        else {
              callback()
        }
    };
    var zheng=(rule, value,callback)=>{
              let reg = /^[1-9]([0-9])*$/;
              let flag = reg.test(value);

                if(!flag){
                   callback(new Error('需为正整数'))
                }
                else if (!value){
                      callback(new Error('请输入！'))
                }     
                else {
                      callback()
                }
            };
      var zheng3=(rule, value,callback)=>{
          let reg = /^[1-9]([0-9])*$/;
          let flag = reg.test(value);

            if(!flag){
                callback(new Error('需为正整数'))
            }
            else if (!value){
                  callback(new Error('请输入！'))
            }  
            else if (Number(value)>999){
                  callback(new Error('不能大于999'))
            }    
            else {
                  callback()
            }
        };

        // 不包含0： \b0(\.\d{1,2})\b
        // 包含0： \b0(\.\d{1,2})?\b
        // 包含0和1： \b(0(\.\d{1,2})?)|1\b

      var zheng4=(rule, value,callback)=>{
          let reg = /^(1|0(\.\d{1,2})?)$/;
          let flag = reg.test(value);

            if(!flag){
                callback(new Error('请输入0-1之间最多两位小数'))
            }
            else if (!value){
                  callback(new Error('请输入！'))
            }  
              
            else {
                  callback()
            }
        };


      //  保留一位小数 ^(0|-?[1-9][0-9]*)(\.\d)?$"
        var xiaoshu=(rule, value,callback)=>{
              let reg = /^(0|-?[1-9][0-9]*)(\.\d)?$/;
              let flag = reg.test(value);

                if(!flag){
                   callback(new Error('请保留一位小数'))
                }
                else if (!value){
                      callback(new Error('请输入！'))
                }     
                else {
                      callback()
                }
            };
    



    return {
        type:'', // 静 动 振动
        disabled1:'',
        disabled2:'',
        zhendong: false,
        state:'1',
        msgList:[
        {id:1,title:'测点编号',icon:'#iconbianhao',value:''},
        {id:2,title:'监测类型',icon:'#iconshebeiguanli',value:''},
        {id:3,title:'监测指标',icon:'#iconzhibiao',value:''},
        {id:4,title:'数据类型',icon:'#iconshuju1',value:''},
        {id:5,title:'位置描述',icon:'#iconmiaoshu',value:''},
        {id:6,title:'传感器型号',icon:'#iconqunfengshebeixinghao',value:''},
        {id:7,title:'系数',icon:'#iconjuleixishu',value:''},
        {id:8,title:'单位',icon:'#iconwenduji',value:''},
        {id:9,title:'精度',icon:'#iconslamjingdu-gongzuojuli',value:''},
        {id:10,title:'通讯地址',icon:'#icondianhua',value:''},
        {id:11,title:'采样时间间隔(秒)',icon:'#iconshijianjiange',value:''},
      ],

       targetOptions1: [        //1：previous，2：next，3：nearest，4：linear，5：spline，6：pchip:7：makima:8：movmean:9：movmedian
        {id:1,name: 'previous'},
        {id:2,name: 'next'},
        {id:3,name: 'nearest'},
        {id:4,name: 'linear'},
        {id:5,name: 'spline'},
        {id:6,name: 'pchip'},
        {id:7,name: 'makima'},
        {id:8,name: 'movmean'},
        {id:9,name: 'movmedian'},
      ],

      selectedArr1:'0',  //是否矩形
      selectedArr2:'0',   //是否低通
      selectedArr3:'0',  //是否陷阱



      methodOptions: [
        {id: 1,name: '矩形窗', value:'0'},
        {id: 2,name: '低通滤波', value:'0'},
        {id: 3,name: '陷波滤波', value:'0'}
      ],
      // 消噪是否显示 3个选项
      show1: false,
      show2: false,
      show3: false,
      // 缺失是否显示
      // dataShow:false,   
      // //  异常是否显示
      // abnormal:false,



    //基线
      formData4:{
         status:'0',
         status1:'1',
         interval:'', // 时间间隔
         num1:'',
         num2:'',
         num3:'',
      },

      //动静分离
       formData5:{
         status:'0',
         num1:'',
         num2:'',
         num3:''
      },



      formData: {
        status:'',
      },
      typeOptions: [
        {id:1,name: '全部'},
        {id:2,name: '荷载类'},
        {id:3,name: '环境类'},
        {id:4,name: '效应类'},
        {id:5,name: '衍生指标类'}
      ],
      targetOptions: [
        {id:1,name: '全部'},
        {id:2,name: '车辆荷载'},
        {id:3,name: '船舶及冰凌撞击荷载'},
        {id:4,name: '风荷载'},
        {id:5,name: '地震动监测'},
        {id:6,name: '其他'}
      ],
      selectedArr: [],
 
      id: this.$route.params.id || sessionStorage.getItem('handleId'),
      targetId:'', //传感器id

    
      rules4: {
        interval: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: checkAge, trigger: 'blur' },
          { validator: zheng, trigger: "blur" }
        ],
        num1: [  //时段长度
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: text1, trigger: "blur" }    //xiaoshu
        ],
         num2: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: zheng4, trigger: "blur" }
        ],
          num3: [
          { required: true, message: '请输入', trigger: 'blur' },
          { validator: text3, trigger: "blur" }
        ],
      }, 


      rules5: {
        num1: [
          { required: true, message: '请输入', trigger: 'blur' },
          //  { validator: checkAge1, trigger: 'blur' },
            { validator: text1, trigger: "blur" }
        ],
        num2: [
          { required: true, message: '请输入', trigger: 'blur' },
           { validator: text3, trigger: "blur" }     //checkedSerialNumber1
        ],
      }, 

      
    }
  },
  watch: {
        msg: function(newVal, oldVal) {
        }
    },

  created () {
    // this.id = sessionStorage.getItem('handleId')
    this.state = sessionStorage.getItem('isAdmin')
    // console.log('999999', this.state)

  },
  mounted () {
    // this.id = this.$route.params.id;
    // console.log('id', this.id)
    this.details1();

  },
  methods: {
    //获取详情       传感器管理---id查询传感器信息
    details1(){
      this.$axios.get(`${this.baseURL}base/baseTarget/${this.id}`).then(res => {
        console.log('详情1',res);
        var data = res.data.data;
        // console.log(data)
        this.msgList[0].value = data.code;         //编号
        this.msgList[1].value = data.typeGroupName;     //监测类型   typeGroupName
        this.msgList[2].value = data.typeName;      //监测指标   typeName
        this.msgList[3].value = data.staticDynamicSelected == "1"?"静态效应":data.staticDynamicSelected =="2"?"动态效应":"振动响应";     //数据类型
        this.msgList[4].value = data.site;      //位置描述
        this.msgList[5].value = data.model;         //传感器型号
        this.msgList[6].value = data.coefficient;      //系数
        this.msgList[7].value = data.unit;            //单位
        this.msgList[8].value = data.precisions;      //精度
        this.msgList[9].value = data.serialNumber;      //通讯地址
        this.msgList[10].value = data.frequency;      //通讯地址

        if(this.msgList[3].value == 3){
            this.zhendong = false;
        }else{
            this.zhendong = true;
        }
        this.type = data.staticDynamicSelected;
        // this.type = 3;
        if(this.type == 1){
            this.disabled1 = true;
            this.disabled2 = true;
        }else if(this.type == 3){    //振动时不做动静分离
            this.disabled1 = false;
            this.disabled2 = true;
            this.details2();
        }
        else{
            this.disabled1 = false;
            this.disabled2 = false;
            this.details2();
        }
       
      })
    },

    //详情主题   //配置管理   数据管理设置，根据id查询实例
    details2(){
      this.$axios.get(`${this.baseURL}setttings/settingsDataClean/option/${this.id}`).then(res => {
      var data = res.data.data;
      console.log('详情2',data);

        this.targetId = this.id; //传感器ID
        for(let key  in data){
          if(data[key]==null){
            data[key]=''
          }
          //  console.log(key + '---' + data[key])
      }

        this.checkboxSelect();   //判断check是否显示

        //基线
        this.formData4.status = data.baselineSelected.toString() || '0';   //是否基线
        this.formData4.num1 = data.baselineDuration;   //基线长度
        this.formData4.num2 = data.baselineRatio;   //基线系数
        this.formData4.interval = data.baselinePeriod; //时间间隔
        this.formData4.status1 = data.baselineType.toString();    //类别
        this.formData4.num3 = data.baselineCutoffFrequency;    //时间间隔

        //动静分类
        this.formData5.status = data.staticDynamicSeparationSelected.toString()  || '0';  //是否动静分离
        this.formData5.num1 = data.staticDynamicDuration;        //动静时长
        this.formData5.num2 = data.staticDynamicCutoffFrequency;   //动静分离截止频率
        // this.formData5.num3 = data.staticDynamicPeriod;   //动静 时间间隔

        // this.formData5.num3 = data.baselinePeriod;   // 基线时间间隔

        if(this.formData4.status == 0){
           this.formData5.num3 =  data.staticDynamicPeriod;
        }
        if(this.formData4.status == 1){
           this.formData5.num3 =  data.staticDynamicPeriod;
        }
        if(this.type == 3){   //振动时不做动静分离
          this.formData5.status = '0';
        }
        if(this.formData4.status1 == 2){
            this.formData4.num3 = data.baselineCutoffFrequency;
        }
        
      })
    },

    saveData(){
      if(this.type == 1){
         this.$router.push('/secondary');    //静态时不保存直接回到列表
      }else if(this.type == 2){
          //动态
          this.saveData1();
      }else{
         //振动
          const p4=new Promise((resolve,reject)=>{
            this.$refs['formData4'].validate((valid) => {
                      if(valid) resolve()
                  })
            })
            Promise.all([p4]).then(()=>{
              // console.log('验证通过,提交表单') 
              this.updated();
          }) 
      }
    },

     //更新请求--振动
    updated() {

        var param = new URLSearchParams();
        param.append("targetId",this.targetId);    //传感器id
  
        param.append("baselineSelected",  this.formData4.status);    //-基线校正是否
        param.append("baselinePeriod",  this.formData4.interval);    //-时间间隔
        param.append("baselineDuration",  this.formData4.num1);    //-基线校正时段长度
        param.append("baselineRatio",   this.formData4.num2);    //-基线校正规则系数

     for (let p of param) {  //查看
        // console.log(p);
      }

      this.$axios.post(`${this.baseURL}setttings/settingsDataClean/save`, param)
        .then(res=>{
          // console.log('更新',res.data);
          if(res.data.status == '200'){
            // console.log(200)
              this.$router.push('/secondary');
          }

      
        })
        .catch(err=>{
        });
    },


    //更新处理---动态
    saveData1(){
      const p4=new Promise((resolve,reject)=>{
        this.$refs['formData4'].validate((valid) => {
                if(valid) resolve()
            })
      })

      const p5=new Promise((resolve,reject)=>{
        this.$refs['formData5'].validate((valid) => {
                if(valid) resolve()
            })
      })

      Promise.all([p4,p5]).then(()=>{
          // console.log('验证通过,提交表单') 
          this.updated1();
            
        })  
    
    },

    //更新请求
    updated1() {

      var param = new URLSearchParams();
      param.append("targetId",this.targetId);    //传感器id
  
      param.append("baselineSelected",  this.formData4.status);    //-基线校正是否
      param.append("baselineDuration",  this.formData4.num1);    //-基线校正时段长度
      param.append("baselineRatio",   this.formData4.num2);    //-基线校正规则系数
      param.append("baselinePeriod",  this.formData4.interval);    //-时间间隔  
      param.append("baselineType",  this.formData4.status1);    //-类别参数
      // param.append("baselineCutoffFrequency",  this.formData4.interval);    //-时间间隔 
      
      param.append("staticDynamicSeparationSelected",  this.formData5.status);    //-动静分离是否
      param.append("staticDynamicDuration",   this.formData5.num1);    //-动静分离时段长度
      param.append("staticDynamicCutoffFrequency", this.formData5.num2);    //-动静分离截止频率
      //  param.append("baselinePeriod", this.formData5.num3);    //-动静 时间间隔
      if(this.formData4.status == 0){
         param.append("staticDynamicPeriod", this.formData5.num3);    //-动静 时间间隔
      }
      if(this.formData4.status == 1){
         param.append("staticDynamicPeriod", this.formData4.interval);    //-动静 时间间隔
      }

      if(this.formData4.status1 == 2){
          param.append("baselineCutoffFrequency", this.formData4.num3);    //-动静 时间间隔
      }



     for (let p of param) {  //查看
        console.log(p);
       
      }

      this.$axios.post(`${this.baseURL}setttings/settingsDataClean/save`, param)
        .then(res=>{
          // console.log('更新',res.data);
          if(res.data.status == '200'){
            // console.log(200)
              this.$router.push('/secondary');
          }

      
        })
        .catch(err=>{
        });
    },


    


    //是否1
    changeStatus1(){
      // console.log('是否确实',this.formData1.status)
    },
    changeNumber1(){
      //  console.log(this.formData1.number)
    },

    //消噪处理方式
    changeArr(){
      // console.log( this.formData3.selectedArr)
      this.checkboxSelect();
    },

    //消噪方法是否显示
    checkboxSelect(){
      // console.log(777,this.formData3.selectedArr)
      // this.formData3.selectedArr.indexOf('a')
      // console.log(6666,  this.formData3.selectedArr.indexOf(1))

      // if(this.formData3.selectedArr.indexOf(1) == -1){     //-1存在  0存在
      //   this.show1 = false;
      // }else{
      //   this.show1 = true;
      //   this.selectedArr1 = 1;
      // }

      // if(this.formData3.selectedArr.indexOf(2) == -1){     //-1存在  0存在
      //   this.show2 = false;
      // }else{
      //   this.show2 = true;
      //   this.selectedArr2 = 1;
      // }

      // if(this.formData3.selectedArr.indexOf(3) == -1){     //-1存在  0存在
      //   this.show3 = false;
      // }else{
      //   this.show3 = true;
      //   this.selectedArr3 = 1;
      // }

    },

    back(){
      this.$router.push('/secondary')

    },



   
  },
}
</script>

<style lang="scss" scoped>
// .multi-form section{
//   height: 58px;
// }
::v-deep .el-form-item__error{
  top: 42px;
}


@media screen and (min-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 178px;
    }
    .el-form-item__label {
      width: 152px;
    }
    .multi-form .el-form {
      padding: 0 1% 0 6.47%;
    }
    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }
      .el-form-item:nth-child(2) {
        margin-right: 100px;
      }
      .el-form-item__label {
        width: 100px;
      }
    }
    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 70px;
        }
      }
    }
    .limit-value {
      .el-form-item {
        width: 258px;
        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}
@media screen and (max-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }
    .el-form:first-child,
    .derive {
      .el-form-item__label {
        width: 110px;
      }
    }
    .multi-form .el-form {
      padding-left: 20px;
    }
    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }
      .el-form-item:nth-child(2) {
        margin: 0 80px 0 15px;
      }
      .el-form-item__label {
        width: 60px;
      }
    }
    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 72px;
        }
      }
      .el-form-item {
        margin-right: 20px !important;
      }
    }
    .limit-value {
      .el-form-item {
        width: 220px;
        .el-form-item__label {
          margin-right: 10px !important;
        }
        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }
      .el-form-item:nth-child(odd) {
        width: 200px;
        .el-form-item__label {
          width: 70px !important;
        }
      }
    }
  }
  .derive::v-deep {
    .el-input,
    .el-input__inner {
      width: 183px;
    }
  }
}
  .el-form::v-deep {
    .el-form-item__label {
      padding: 0;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #0c265a;
    }
    .el-form-item__content {
      height: 60px;
      width: auto;
      line-height: 60px;
      text-align: center;
    }
    .el-input__inner {
      padding: 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    .el-select {
      .el-input__inner {
        text-align: left;
        padding-left: 15px;
        border: 1px solid rgba(151, 151, 151, 0.29);
      }
      .el-input__icon {
        height: 30px;
        line-height: 30px;
      }
    }
    .el-input.is-disabled .el-input__inner {
      border: 1px solid #383E41 !important;
      background: rgba(56, 63, 65, 0.29) !important;
    }
  }
  .long-input::v-deep {
    .el-input__inner {
      width: 90%;
    }
  }
  p {
    color: #DBF804;
    line-height: 40px;
    text-align: center;
  }
  .el-form-item {
    margin: 0;
    border-top: 1px solid #5e5e5f2e;
  }
  .el-form-item {
    display: inline-flex;
    &::v-deep {
      .el-form-item__content {
        flex: 1;
      }
    }
  }
  .title {
    width: 152px;
    display: flex;
    word-wrap: none;
    white-space: nowrap;
    color: #DBF804;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #5E5E5F;
    background: #0c265a;
  }
  .derive {
    &::v-deep {
      .el-form-item__label {
        margin-right: 22px;
      }
    }
  }
  .multi-form,
  .el-form:first-of-type,
  .el-form:nth-of-type(2) {
    margin: 4px;
    border-radius: 8px;
    margin-bottom: 26px;
    border: 1px solid #5E5E5F;
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  }
  .el-form:first-child {
    &::v-deep .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding: 0;
        border: none;
      }
    }
    .el-form-item {
      width: 25%;
    }
    .el-form-item:nth-of-type(5) {
      width: 100%;
    }
    .el-form-item:nth-last-of-type(2) {
      width: 50%;
    }
    .el-form-item:nth-of-type(n+6) {
      border-bottom: 1px solid #5E5E5F;
    }
    .el-form-item:nth-of-type(n+10) {
      border: none;
    }
  }
  .el-form:nth-child(2) {
    .el-form-item {
      display: block;
      &::v-deep {
        .el-form-item__content {
          display: inline-flex;
        }
      }
      .el-select:not(:last-child) {
        margin-right: 22px;
      }
    }
  }
  .multi-form {
    section {
      display: flex;
      border-top: 1px solid #5e5e5f2e;
      p {
        width: 152px;
        height: 60px;
        line-height: 60px;
        background: #0c265a;
      }
      .el-form {
        flex: 1;
        margin: 0;
        border: none;
        display: flex;
        flex-wrap: wrap;
        box-shadow: none;
        .el-form-item {
          border: none;
          height: 60px;
          &::v-deep {
            .el-form-item__label {
              text-align: left;
              margin-right: 20px;
              white-space: nowrap;
              background: transparent;
            }
          }
          .unit {
            padding: 0 12px;
            white-space: nowrap;
          }
        }
      }
    }
    .denosing-form {
      .el-form {
        margin: 0;
        border-radius: 0;
        border-bottom: 1px solid #5e5e5f2e;
      }
      .el-form-item:nth-of-type(2) {
        width: 50%;
        &::v-deep {
          .el-form-item__content {
            white-space: nowrap;
          }
        }
      }
    }
    .rehandle {
      .el-form-item:first-child {
        &::v-deep .el-form-item__label{
          width: 182px;
        }
      }
    }
  }
  .multi-form:nth-of-type(-n+1) {
    .el-form-item {
      margin-right: 4%;
    }
  }
  .set-time {
    padding: 0 2% !important;
  }
  .set-threshold {
    .title {
      border: none;
    }
    .form-title {
      margin: 20px 0;
      span {
        width: 50%;
        display: inline-block;
      }
    }
    .form-list {
      flex: 1;
      padding: 0 0 23px 2%;
      .el-form {
        padding: 0;
        &::v-deep {
          .el-form-item__content {
            width: 78px;
          }
          .el-form-item__label {
            padding: 0;
            width: 90px;
          }
        }
      }
      .limit-value {
        width: 50%;
        .el-form-item {
          display: inline-flex;
        }
      }
    }
  }
  .btns {
    margin-top: 44px;
    text-align: center;
    .el-button {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    }
    .el-button:last-child {
      margin-left: 50px;
      background: #1E2529;
    }
  }


  .div{
    margin-bottom: 20px;
    ::v-deep .el-form{
      .ml20{
        margin-left: 20px;
      }
      .el-form-item__label{
        font-size: 16px;
        font-weight: 500;
        color:#fff;
        text-align: left;
        line-height: 30px;
        width: 102px;
        padding:0;
      }
      .el-form-item{
        margin-bottom: 0;
        display: flex;
        &.mB20{
          margin-bottom: 20px;
        }
        .el-form-item__content{
          margin-left: 0 !important;
          line-height: 30px;
        }
      }
      .line{
        padding: 0 10px;
      }
      .el-input{
        width: 134px;
        height: 30px;
        .el-input__inner{
          font-size: 16px;
          color:#fff;
        }
        .el-select__caret{
          line-height: 30px;
        }
        &.el-date-editor{
          width: 162px;
          .el-input__inner{
            padding:0 0 0 8px;
            font-size: 14px;
          }
          .el-input__prefix{
            display: none;
          }
        }

      }
      .el-button.ml24{margin-left: 24px;}
    }
    .title{margin:14px 0 8px 0;}
    .msgList{
      li{
        height: 48px;
        line-height: 48px;
        margin-top: 4px;
        .labelTxt{
          padding-left: 20px;
          background: rgba(48, 56, 58, 0.69);
          min-width: 131px;
          span{padding-left: 4px;}
        }
        .contentTxt{
          font-weight: 500;
          padding-left: 16px;
          width: 134px;
          background: rgba(34,39,40, 0.41);
        }
        &:nth-child(5){
          flex:1;
          .contentTxt{
            flex: 1;
            max-width: 399px;
          }
        }
      }
    }
    .myChart{
      height: 200px;
    }
    .el-checkbox{
      margin-left: 38px;
      margin-right: 0;
    }
    .toolTipList{
      li{
        margin-left: 14px;
        &:first-child{
          margin-left: 0;
        }
        .lineColor{
          width:39px;
          height: 1px;
          //background-color: #8AE8FF;
        }
        .pl10{
          padding-left: 8px;
        }
      }
    }
  }
  //@media screen and (max-width:1540px) {
  @media screen and (max-width:1848px) {
    .main{
      ::v-deep .el-form{
        .el-form-item__label {
          font-size:14px;
          width: 94px;
        }
        .ml20{
          margin-left: 0px;
        }
        .el-input{
          width: 120px;
          height: 30px;
          .el-input__inner{
            font-size: 14px;
          }

        }
        button.el-button{
          width: 90px;
          height: 30px;
          flex-shrink: 0;
          &.ml24{margin-left: 10px;}
        }
      }
    }

  }

//

  @media screen and (min-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 118px;   //178 155
    }
    .el-form-item__label {
      width: 152px;
    }
    .multi-form .el-form {
      padding: 0 1% 0 0.47%;
    }
    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }
      .el-form-item:nth-child(2) {
        margin-right: 100px;
      }
      .el-form-item__label {
        width: 100px;
      }
    }
    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 70px;
        }
      }
    }
    .limit-value {
      .el-form-item {
        width: 258px;
        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}
@media screen and (max-width:1650px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }
    .el-form:first-child,
    .derive {
      .el-form-item__label {
        width: 110px;
      }
    }
    .multi-form .el-form {
      padding-left: 20px;
    }
    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }
      .el-form-item:nth-child(2) {
        margin: 0 80px 0 15px;
      }
      .el-form-item__label {
        width: 60px;
      }
    }
    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label{
          width: 72px;
        }
      }
      .el-form-item {
        margin-right: 20px !important;
      }
    }
    .limit-value {
      .el-form-item {
        width: 220px;
        .el-form-item__label {
          margin-right: 10px !important;
        }
        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }
      .el-form-item:nth-child(odd) {
        width: 200px;
        .el-form-item__label {
          width: 70px !important;
        }
      }
    }
  }
  .derive::v-deep {
    .el-input,
    .el-input__inner {
      width: 183px;
    }
  }
}
  .el-form::v-deep {
    .el-form-item__label {
      padding: 0;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #0c265a;
    }
    .el-form-item__content {
      height: 60px;
      width: auto;
      line-height: 60px;
      text-align: center;
    }
    .el-input__inner {
      padding: 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    .el-select {
      .el-input__inner {
        text-align: left;
        padding-left: 15px;
        border: 1px solid rgba(151, 151, 151, 0.29);
      }
      .el-input__icon {
        height: 30px;
        line-height: 30px;
      }
    }
    .el-input.is-disabled .el-input__inner {
      border: 1px solid #383E41 !important;
      background: rgba(56, 63, 65, 0.29) !important;
    }
  }
  .long-input::v-deep {
    .el-input__inner {
      width: 90%;
    }
  }
  p {
    color: #DBF804;
    line-height: 40px;
    text-align: center;
  }
  .el-form-item {
    margin: 0;
    border-top: 1px solid #5e5e5f2e;
  }
  .el-form-item {
    display: inline-flex;
    &::v-deep {
      .el-form-item__content {
        flex: 1;
      }
    }
  }
  .title {
    width: 152px;
    display: flex;
    word-wrap: none;
    white-space: nowrap;
    color: #DBF804;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #5e5e5f2e;
    background: #0c265a;
  }
  .derive {
    &::v-deep {
      .el-form-item__label {
        margin-right: 22px;
      }
    }
  }
  .multi-form,
  .el-form:first-of-type,
  .el-form:nth-of-type(2) {
    margin: 4px;
    border-radius: 8px;
    margin-bottom: 26px;
    border: 1px solid #5e5e5f2e;
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  }
  .el-form:first-child {
    &::v-deep .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding: 0;
        border: none;
      }
    }
    .el-form-item {
      width: 25%;
    }
    .el-form-item:nth-of-type(5) {
      width: 100%;
    }
    .el-form-item:nth-last-of-type(2) {
      width: 50%;
    }
    .el-form-item:nth-of-type(n+6) {
      border-bottom: 1px solid #5e5e5f2e;
    }
    .el-form-item:nth-of-type(n+10) {
      border: none;
    }
  }
  .el-form:nth-child(2) {
    .el-form-item {
      display: block;
      &::v-deep {
        .el-form-item__content {
          display: inline-flex;
        }
      }
      .el-select:not(:last-child) {
        margin-right: 22px;
      }
    }
  }
  .multi-form {
    section {
      display: flex;
      border-top: 1px solid #5e5e5f2e;
      p {
        width: 152px;
        height: 60px;
        line-height: 60px;
        background: #0c265a;
      }
      .el-form {
        flex: 1;
        margin: 0;
        border: none;
        display: flex;
        flex-wrap: wrap;
        box-shadow: none;
        .el-form-item {
          border: none;
          height: 60px;
          &::v-deep {
            .el-form-item__label {
              text-align: left;
              margin-right: 20px;
              white-space: nowrap;
              background: transparent;
            }
          }
          .unit {
            padding: 0 12px;
            white-space: nowrap;
          }
        }
      }
    }
    .denosing-form {
      .el-form {
        margin: 0;
        border-radius: 0;
        border-bottom: 1px solid #5e5e5f2e;
      }
      .el-form-item:nth-of-type(2) {
        width: 50%;
        &::v-deep {
          .el-form-item__content {
            white-space: nowrap;
          }
        }
      }
    }
    .rehandle {
      .el-form-item:first-child {
        &::v-deep .el-form-item__label{
          width: 182px;
        }
      }
    }
  }
  .multi-form:nth-of-type(-n+1) {
    .el-form-item {
      margin-right: 4%;
    }
  }
  .set-time {
    padding: 0 2% !important;
  }
  .set-threshold {
    .title {
      border: none;
    }
    .form-title {
      margin: 20px 0;
      span {
        width: 50%;
        display: inline-block;
      }
    }
    .form-list {
      flex: 1;
      padding: 0 0 23px 2%;
      .el-form {
        padding: 0;
        &::v-deep {
          .el-form-item__content {
            width: 78px;
          }
          .el-form-item__label {
            padding: 0;
            width: 90px;
          }
        }
      }
      .limit-value {
        width: 50%;
        .el-form-item {
          display: inline-flex;
        }
      }
    }
  }
  .btns {
    margin-top: 44px;
    text-align: center;
    .el-button {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    }
    .el-button:last-child {
      margin-left: 50px;
      background: #1E2529;
    }
  }

.marginL10{
  margin-left: 10px !important;
}
.marginR10{
  margin-right: 10px !important;
}

::v-deep .itemForm label{
  // padding-left: 40px !important;
}

.hui{
       cursor: not-allowed;
      background: #1E2529 !important;
}
.hui:hover{
      cursor: not-allowed;
      background: #1E2529 !important;
}

::v-deep .el-select .el-input__inner {
    // display: inline-block;
    // max-width: 120px;
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


</style>